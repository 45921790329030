





























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LC_Task84',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          img: '/img/assignments/uciTask84_optionA.png',
          value: 'A',
        },
        {
          img: '/img/assignments/uciTask84_optionB.png',
          value: 'B',
        },
        {
          img: '/img/assignments/uciTask84_optionC.png',
          value: 'C',
        },
        {
          img: '/img/assignments/uciTask84_optionD.png',
          value: 'D',
        },
      ],
    };
  },
};
